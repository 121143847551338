import {
  Salina,
  Avan,
  Hellen,
  Kaitlyn,
  Barry,
  Fred,
  Wilbur,
  Max,
  Collin,
  Tristan,
  Allison,
  Molly,
  Michelle,
  Peyton,
  Ruixi,
} from "../../images/team/index";

const executives = [
  {
    name: "Avan Chan",
    position: "President",
    linkedin: "https://ca.linkedin.com/in/avan-chan-00bb4224a",
    instagram: "https://www.instagram.com/avancognito/",
    email: "achan735@uwo.ca",
    image: Avan,
    calendly: "",
  },
  {
    name: "Tristan Mandy",
    position: "President",
    linkedin: "https://www.linkedin.com/in/tristan-mandy/",
    instagram: "https://www.instagram.com/torrtan/",
    email: "imandy@uwo.ca",
    image: Tristan,
    calendly: "",
  },
  {
    name: "Braden Ream",
    position: "Senior Advisor",
    facebook: "https://www.facebook.com/abhi.ravipati.50",
    linkedin: "https://www.linkedin.com/in/abhiravipati/",
    instagram: "https://www.instagram.com/_abhi_ravipati/",
    email: "aravipa@uwo.ca",
    image: Tristan,
    calendly: "",
  },
  {
    name: "Salina Guo",
    position: "Advisor",
    linkedin: "https://www.linkedin.com/in/salina-guo-28409a1b1/",
    facebook: "https://www.facebook.com/salina.guo.7",
    instagram: "https://www.instagram.com/salinaguo/",
    email: "sguo.hba2025@ivey.ca",
    image: Tristan,
    calendly: "",
  },
  {
    name: "Barry Paul",
    position: "VP Developments",
    linkedin: "https://www.linkedin.com/in/barryxpaul/",
    instagram: "https://www.instagram.com/barryxpaul/",
    email: "bpaul33@uwo.ca",
    image: Barry,
    calendly: "",
  },
  {
    name: "Ada Shen",
    position: "VP Developments",
    linkedin: "https://www.linkedin.com/in/ada-shen-131718207/",
    instagram: "https://www.instagram.com/adas518/",
    email: "ashen62@uwo.ca",
    image: Tristan,

  },
  {
    name: "Hellen Liu",
    position: "VP Innovation",
    linkedin: "https://www.linkedin.com/in/hellen-liu/",
    instagram: "https://www.instagram.com/hellenliu_/",
    email: "hliu986@uwo.ca",
    image: Hellen,
    calendly: "",
  },
  {
    name: "Alex Wang",
    position: "VP Innovation",
    linkedin: "https://www.linkedin.com/in/alexwang87/",
    instagram: "https://www.instagram.com/@alex_da_coolkidwowowowang/",
    email: "awang549@uwo.ca",
    image: Tristan,
    calendly: "",
  },
  {
    name: "Wilbur D'Souza",
    position: "VP Community",
    linkedin: "https://www.linkedin.com/in/wilbur-d-souza-541a1a1b3/",
    instagram: "https://www.instagram.com/wilbur.dsouza_/",
    email: "wdsouza3@uwo.ca",
    image: Wilbur,
    calendly: "",
  },
  {
    name: "Max Skubitsky",
    position: "VP Community",
    linkedin: "https://www.linkedin.com/in/maxim-skubitsky-40b6782a7/",
    instagram: "https://www.instagram.com/sxam03/",
    email: "mskubits@uwo.ca",
    image: Max,
    calendly: "",
  },
  {
    name: "Rui Xi Qiu",
    position: "VP Marketing",
    linkedin: "https://www.linkedin.com/in/ruixi-qiu",
    instagram: "https://www.instagram.com/ruixiq/",
    email: "rqiu27@uwo.ca",
    image: Ruixi,
    calendly: "",
  },
  {
    name: "Kaitlyn Lee",
    position: "VP Marketing",
    linkedin: "http://www.linkedin.com/in/kaitlyn-lee-733924261",
    instagram: "https://www.instagram.com/kaitlynmakayla_/",
    email: "klee936@uwo.ca",
    image: Kaitlyn,
    calendly: "",
  },
  {
    name: "Allison Ye",
    position: "VP Catalyst",
    linkedin: "https://www.linkedin.com/in/-allison-ye/",
    instagram: "https://www.instagram.com/a.llisonye/",
    email: "aye28@uwo.ca",
    image: Allison,
    calendly: "",
  },
  {
    name: "Frederick Yu",
    position: "VP Catalyst",
    linkedin: "https://www.linkedin.com/in/frederick-yu-968b31204/",
    instagram: "https://www.instagram.com/frederickyu_/",
    email: "fyu92@uwo.ca",
    image: Fred,
    calendly: "",
  },
  {
    name: "Molly Jin",
    position: "VP Accelerators",
    linkedin: "https://www.linkedin.com/in/molly-jin/",
    instagram: "https://www.instagram.com/m._.llyyy/",
    email: "mjin95@uwo.ca",
    image: Molly,
    calendly: "",
  },
  {
    name: "Collin Sobowale",
    position: "VP Accelerators",
    linkedin: "https://www.linkedin.com/in/collinsbwle/",
    instagram: "https://www.instagram.com/collin.sbwl/",
    email: "csobowal@uwo.ca",
    image: Collin,
    calendly: "",
  },
];

export { executives };